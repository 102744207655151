* {
    font-family : 'SparkasseWeb'
}

.teaser-text {
    min-height : 180px;
    max-height : 180px;
    height : 180px;
}

.panel-default2 {
    min-height : 60%;
}

.panel-default2 h2 {
    color : red;
    font-size : 25px;
}
.panel-default2 p, .panel-default2 table {
    color : black;
    font-size : 18px;
}

.rel {
    position : relative;
}

.badge-secondary {
    position : absolute !important;
    background: #00D900 !important;
    border-radius: 50px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    top: -15px !important;
    right : -23px;
}



.alert {
    display : inline-block;
    margin-bottom : 0px;
    margin-top : 22px;
    padding : 0;
    width : 100%;
    position : relative;
    height : 58px;

    .alert-header {
        position : absolute;
        display : inline-block; 
        padding : 7px 15px 0;
        font-size : 30px;
        height : 56px;
        width : 56px;
    }

    .alert-body {
        position : absolute;
        left : 58px;
        display : inline-block;
        padding : 16px 10px;
    }
}

.alert-success {
    border : 2px solid #3c763d;
    .alert-header {
        border-right : 2px solid #3c763d;
    }
}

.alert-info {
    border : 2px solid #31708f;
    .alert-header {
        border-right : 2px solid #31708f;
    }
}


.navbar.navbar-default .navbar-nav > li.nav-item > a,
.navbar.navbar-default .navbar-header .navbar-brand > a,
.navbar.navbar-default .form-inline > .form-group > .form-row > a
{
    color : #ffffff;
    text-decoration : none;

    &:hover, &:active, &:visited
    {
        text-decoration : none;
    }
}

.navbar.navbar-default .navbar-form .form-control,
.navbar.navbar-default .navbar-form .form-control:focus {
    border : none;
    outline : none;
    box-shadow : none;
    transition : none;
}

.navbar {
    background : #ff0000;
    border-radius : 0px;
    border : none;
    &.navbar-bottom {
        margin-bottom : 0px;
    }
    .navbar-brand {
        height : 96px;
        padding-top : 0px;
        margin-left : -26px !important;
        & > img {
            display : inline-block;
            padding-top : 4px;
        }
    }
    .navbar-form {
        margin-top : 27px;
    }
    a.btn-primary {
        padding : 5px !important;
    }
    .btn-primary {
        background : #ff0000;
        border : 2px solid #ffffff;
        border-radius : 5px;
        &:visited {
            background : #ff0000;
            box-shadow : none;
        }
        &:hover, &:active {
            background : #ffffff;
            box-shadow : none;
            border : 2px solid #ffffff;
            color : #ff0000;
        }
    }
}

.panel.panel-default
{

//    margin-bottom : 100px;
    border-color : #e3e3e3;
    border-radius : 0;
    box-shadow : none;
    & > .panel-heading
    {
        font-size : 22px;
        color : #ff0000;
        border-bottom : 2px solid #e3e3e3;
        position : relative;
        & label {
            font-size : 12px;
            color : #000000;
        }
    }

    & > p {
        padding : 15px;
        font-size : 18px;
        color : black;
        margin : 0;
    }
}

.new-button {
    position : absolute;
    top : 7px;
    right : 7px;
}

body {
    background : #e3e3e3;
}

.no-padding {
    padding : 0px;
    margin-bottom : 0;
}

table.table {
    margin-bottom : 0px;
}
td {
    vertical-align : middle !important;
}

a.sort-link {
    color : #636b6f !important;
    &:hover, &:active {
        color : #636b6f !important;
    }
}

.navbar-big {
    width : 100%;
    & > .nav-item {
        padding : 15px 10px;
        text-align: center;
    }
    & > .nav-item > a {
        color : #ff0000 !important;
        background : #ffffff !important;
        border-radius : 5px;
        border :  2px solid #ff0000;
        &:hover, &:active {
           background : #ff0000 !important;
           color : #ffffff !important;
        }
    }
    & .glyphicon {
        font-size : 30px;
        display : block;
    }
}

.btn-primary {
    background : #ff0000;
    border : 2px solid #ff0000;
    color : #ffffff;
    border-radius : 5px;
    &:visited {
        background : #ff0000;
        box-shadow : none;
    }
    &:hover, &:active, &:focus {
        background : #ffffff !important;
        box-shadow : none !important;
        border : 2px solid #ff0000 !important;
        color : #ff0000 !important;
    }
}

.btn-primary-green {
    background : #4cae4c;
    border : 2px solid #4cae4c;
    border-radius : 5px;
    color : #ffffff;
    &:visited {
        background : #4cae4c;
        box-shadow : none;
    }
    &:hover, &:active {
        background : #ffffff;
        box-shadow : none;
        border : 2px solid #4cae4c;
        color : #4cae4c;
    }
}



.d-inline-block {
    display : inline-block;
}

.table-bordered {
    border-bottom : 1px solid #ddd !important;
}

.opener {
    position : relative;
    margin-top : -22px;
    .opener-panel {
        position : absolute;
        background-color : #ffffff;
        top : 100px;
        left : 200px;
        max-width: 450px;
        padding : 25px 35px;
        h1 {
            color : #ff0000;
            font-size : 34px;
            padding : 0;
            margin : 0;
        }
        h2 {
            font-size : 20px;
            color : #000000;
            margin-top : 5px;
        }
    }
}

h1 {
    color : #ff0000;
    font-size : 34px;
    padding : 0;
    margin : 0;
    padding-bottom : 20px;
}
h2 {
    font-size : 20px;
    color : #000000;
    margin-top : 5px;
}


.img-teaser {
    max-height : 300px;
}

.no-top-padding {
    padding-top : 0px;
}

.gray-background {
    background-color : #f0f0f0;
}


// big navigation items for home page
$nav-item-big-height : 250px;
li.nav-item-big > a {
    height : $nav-item-big-height;
    min-height : $nav-item-big-height;
    max-height : $nav-item-big-height;
    padding-top : 80px !important;
    & > div.glyphicon {
        font-size : 55px;
        padding-bottom : 5px;
    }
}

.btn-rectangular {
    width : 42px;
    height : 42px;
    min-width : 42px;
    min-height : 42px;
    max-width : 42px;
    max-height : 42px;

    & > .glyphicon {
        padding-top : 5px;
    }
}

// Breadcrumbs

.breadcrumb {
    background : none;
    line-height : 150%;
    margin : 0px;
}


.breadcrumb > li + li:before {
    font-family : "Pictos-IF";
    content : ">" !important;
    color : #ff0000;
    font-size : 20px;
}

li.breadcrumb-item > a, li.breadcrumb-item {
    color : #666666;
    font-size : 14px;
}


// Pagination

ul.pagination {
    margin : 0 0 22px 0;
    & > li.page-item > a {
        color : #666666 !important;
        &:hover, &:active {
            background : #666666;
            color : #ffffff !important;
        }
    }
    .page-item.active {
       border : none !important;
       background-color : #ff0000 !important;
       & > a {
           border : none !important;
           color : #ffffff !important;
           background-color : #ff0000 !important;
       }
    }
}




.fa-big-nav {
    display : block;
    font-size : 55px;
    margin-top : 5px;
}

.navbar-footer {
    margin-left : -13px;
}

.no-result {
    font-weight : bold;
    padding-top : 70px;
    padding-bottom : 70px;
}

.version {
    color : #ffffff;
    font-size: 10px;
    padding-top : 14px;
    padding-bottom : 14px;
}

.glyphicon, .fa {
    position : relative;
}

.glyphicon-big {
    font-size : 28px;
}

.badge-primary {
    position : absolute;
    left : 50%;
    top : -35%;
    background: #00D900;
    border-radius: 50px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    padding-top: 10px;
}

.green {
    color : green;
}


// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

$COLOR_RED : #ff0000;
$COLOR_DARKGRAY : #666666;

$LINE_HEIGHT_DEFAULT : 150%;

$LABEL_COLOR : $COLOR_DARKGRAY;
$LABEL_FONT_SIZE : 14px;
$LABEL_FONT_WEIGHT : normal;

$INPUT_COLOR : $COLOR_DARKGRAY;
$INPUT_FONT_SIZE : 14px;
$INPUT_FONT_WEIGHT : normal;

$ERROR_COLOR : $COLOR_RED;
$ERROR_BORDER_COLOR : $COLOR_RED;
$ERROR_FONT_SIZE : 14px;
$ERROR_FONT_WEIGHT : normal;

// Bootstrap

.navbar {
    margin-bottom : 0;
}

.control-label {
    font-weight : $LABEL_FONT_WEIGHT;
}

.form-control {
    color : $INPUT_COLOR;
    font-size : $INPUT_FONT_SIZE;
    font-weight : $INPUT_FONT_WEIGHT;
}

.help-block {
    display : inline-block;
    margin-bottom : 0px;
}

.has-error .control-label,
.has-error .form-control,
.has-error .help-block
{
    color : $ERROR_COLOR;
    font-size : $ERROR_FONT_SIZE;
}

.has-error .form-control
{
    border-color : $ERROR_BORDER_COLOR;
}
