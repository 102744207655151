@font-face{
  font-family:'SparkasseWeb';
  src:url(/fonts/if6/Sparkasse_web_Rg.eot?#iebug) format('embedded-opentype'),
      url(/fonts/if6/Sparkasse_web_Rg.woff) format('woff'),
      url(/fonts/if6/Sparkasse_web_Rg.ttf) format('truetype');
  font-weight:normal;
  font-style:normal;
}

@font-face{
  font-family:'SparkasseWeb';
  src:url(/fonts/if6/Sparkasse_web_Bd.eot?#iebug) format('embedded-opentype'),
      url(/fonts/if6/Sparkasse_web_Bd.woff) format('woff'),
      url(/fonts/if6/Sparkasse_web_Bd.ttf) format('truetype');
  font-weight:bold;
  font-style:normal;
}

@font-face{
  font-family:'SparkasseWebLight';
  src:url(/fonts/if6/Sparkasse_web_Lt.eot?#iebug) format('embedded-opentype'),
      url(/fonts/if6/Sparkasse_web_Lt.woff) format('woff'),
      url(/fonts/if6/Sparkasse_web_Lt.ttf) format('truetype');
  font-weight:normal;
  font-style:normal;
}

@font-face{
  font-family:'SparkasseHeadWeb';
  src:url(/fonts/if6/SparkasseHead_web_Rg.eot?#iebug) format('embedded-opentype'),
      url(/fonts/if6/SparkasseHead_web_Rg.woff) format('woff'),
      url(/fonts/if6/SparkasseHead_web_Rg.ttf) format('truetype');
  font-weight:normal;
  font-style:normal;
}

@font-face{
  font-family:'Pictos-IF';
  src:url(/fonts/if6/pictos-if.eot?#iebug) format('embedded-opentype'),
      url(/fonts/if6/pictos-if.woff) format('woff'),
      url(/fonts/if6/pictos-if.ttf) format('truetype');
  font-weight:normal;
  font-style:normal;
}
